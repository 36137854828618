<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <!-- eslint-disable vue/no-v-html -->
  <Swiper
    v-if="defaultSlot"
    v-bind="horizontalSwiperSettings"
    :class="[classes]"
    class="horizontal-slider"
    @init="onInit"
  >
    <SwiperSlide
      v-for="(slotEl, index) in defaultSlot()[0].children"
      :key="index"
    >
      <component :is="slotEl" />
    </SwiperSlide>
  </Swiper>

  <template v-if="props.showNavigationButtons">
    <button
      v-bind="$attrs"
      ref="next"
      class="swiper-button-wrapper-next"
      @click="navigate('next')"
    >
      <span
        v-bind="$attrs"
        class="swiper-button-next"
      />
    </button>
    <button
      v-bind="$attrs"
      ref="prev"
      class="swiper-button-wrapper-prev"
      @click="navigate('prev')"
    >
      <span
        v-bind="$attrs"
        class="swiper-button-prev"
      />
    </button>
  </template>

  <div
    data-ssr-styles
    v-html="SSR_GRID_STYLES"
  />
</template>

<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Grid, Navigation, Scrollbar } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import useSsrSwiperStyles from '../../utils/ssrSliderStyles'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/grid'

const props = withDefaults(
  defineProps<{
    classes?: string | null
    slidesPerView?: number
    slidesPerGroup?: number
    lessSlidersOnView?: boolean
    options?: SwiperOptions
    centerInsufficientSlides?: boolean
    autoplay?: boolean
    multiRow?: boolean
    showNavigationButtons?: boolean
  }>(),
  {
    classes: null,
    slidesPerView: 3,
    slidesPerGroup: 1,
    lessSlidersOnView: false,
    options: () => ({}),
    centerInsufficientSlides: false,
    autoplay: false,
    multiRow: false,
    showNavigationButtons: true,
  },
)

const slots = useSlots()
const defaultSlot = slots?.default
const next = ref(null)
const prev = ref(null)

const horizontalSwiperSettings = computed((): SwiperOptions => {
  const swiperModules = [Navigation, Scrollbar]
  if (props.multiRow) {
    swiperModules.push(Grid)
  }

  const swiperOptions = {
    loop: false,
    centerInsufficientSlides: props.centerInsufficientSlides,
    initialSlide: 0,
    spaceBetween: 15,
    slidesPerView: 1.32,
    navigation: {
      nextEl: next.value,
      prevEl: prev.value,
    },
    scrollbar: true,
    modules: swiperModules,
    slidesOffsetAfter: 32,
    breakpoints: {
      480: {
        slidesPerView: 1.8,
        spaceBetween: 24,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 2.7,
      },
      800: {
        slidesPerView: 2.9,
      },
      996: {
        slidesPerView: 3.1,
      },
      1024: {
        slidesPerView: props.lessSlidersOnView ? 2 : 3.3,
        slidesOffsetAfter: 0,
      },
      1300: {
        slidesPerView: props.lessSlidersOnView ? 2 : 4.2,
        slidesOffsetAfter: 0,
      },
      1366: {
        slidesPerView: props.slidesPerView,
        slidesOffsetAfter: 0,
      },
    },
    ...props.options,
  }

  if (props.autoplay) {
    swiperOptions.modules.push(Autoplay)
    swiperOptions.autoplay = {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }
  }

  return swiperOptions
})

const swiper = ref(null)
function onInit(swiperInstance: any) {
  swiper.value = swiperInstance
}

defineExpose({
  swiper,
})

function navigate(direction: string): void {
  if (!swiper.value || swiper.value?.destroyed) {
    return
  }

  let slideBy = 1
  direction === 'prev'
    ? slideBy = swiper.value.activeIndex - props.slidesPerGroup
    : slideBy = swiper.value.activeIndex + props.slidesPerGroup
  swiper.value.slideTo(slideBy)
}

const SSR_CSS = useSsrSwiperStyles(horizontalSwiperSettings.value)
const SSR_GRID_STYLES = computed(() => `<style>${SSR_CSS.join('')}</style>`)

onBeforeUnmount(() => {
  if (swiper.value) {
    swiper.value?.destroy?.()
  }
})
</script>

<style lang="postcss" scoped>
.swiper-button-disabled {
  @apply !hidden;
}
</style>
